/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import Vue from 'vue';
import http from '~/http';
import { localStorage } from '~/shared/utils';
import store from '~/store';

http.interceptors.request.use(
  response => {
    const { franchisee, city } = store.getters;
    const token = localStorage.localStorageGetItem('token');

    if (token && token !== '') {
      response.headers.common.Authorization = `Bearer ${token}`;
      if (city) {
        response.headers.common.city = city.id;
        response.headers.common.franchisee = franchisee.id;
      }
      response.headers.common.timezone = franchisee.timezone;
    }

    return response;
  },

  error => Promise.reject(error),
);

http.interceptors.response.use(
  response => response,
  async error => {
    if (
      error.response.status === 422 ||
      error.response.status === 400 ||
      error.response.status === 404
    ) {
      // Vue.prototype.$alert.show('', errorsApiFormat(error, error.response.status), 'error');
      return Promise.reject(error);
    }

    if (error.response.status === 500) {
      Vue.prototype.$alert.show('', 'Houve um erro interno, já notificamos nossa equipe.', 'error');
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      store.dispatch('logout');
      window.location = '/';
    }

    return Promise.reject(error);

    // let errorResponse = error.response

    // if (errorResponse.status === 421 || errorResponse.status === 420) {
    //   Vue.prototype.$alert.show('', errorResponse.data.message, 'warning')
    // }

    // if (errorResponse.status === 401) {
    //   return postRefreshToken()
    //     .then(response => {
    //       store.dispatch('refreshToken', response.data)
    //       errorResponse.config.headers['Authorization'] = 'Bearer ' + response.data.access_token
    //       return http(errorResponse.config)
    //     }).catch(error => {
    //       store.dispatch('directLogout')
    //       window.location = '/'
    //       return Promise.reject(error)
    //     })
    // }
    // return Promise.reject(error)
  },
);

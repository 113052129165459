<script>
import http from '~/http';

let typingTimer;

export default {
  name: 'Select2',

  props: {
    name: { String, required: true },
    customLabel: { Function },
    selected: null,
    objectSelected: { type: Object, default: () => ({}) },
    labelSelect: null,
    label: null,
    info: { String, default: '' },
    trackBy: { String, required: true },
    placeholder: { String },
    multiple: { Boolean, default: false },
    required: { Boolean, default: false },
    v: { default: '' },
    textValidations: { default: '' },
    formSm: { default: false },
    errorInPlaceholder: { type: Boolean, default: false },
    ajaxParam: { type: String, default: 'search' },
    ajaxUrl: { type: String, default: null },
    returnObject: { type: Boolean, default: false },
  },

  data() {
    return {
      model: [],
      models: [],
    };
  },

  computed: {
    vName() {
      if (this.each) {
        return this.v[this.name];
      }
      return this.v.form[this.name];
    },

    errors() {
      if (this.v) {
        if (this.vName.$dirty && this.vName.$invalid) {
          const errors = [];
          Object.keys(this.textValidations).forEach(error => {
            errors.push(this.textValidations[error]);
          });
          return errors[0];
        }
      }
      return '';
    },

    isDanger() {
      if (this.v) {
        if (this.vName) {
          if (!this.model && !Object.keys(this.textValidations).find(x => x === 'required')) {
            return false;
          }
          return this.vName.$dirty && this.vName.$invalid;
        }
        return false;
      }
      return false;
    },
  },

  watch: {
    selected() {
      if (!this.selected) {
        window.$(`#select_${this.name}`).val(this.selected).trigger('change');
      }
    },
    objectSelected() {
      if (!this.objectSelected?.id) {
        window.$(`#select_${this.name}`).val(null).trigger('change');
      }
    },
  },

  methods: {
    success() {
      this.$emit('blur');
      if (this.v) {
        setTimeout(() => {
          this.isSuccess = this.vName ? !this.vName.$invalid : !!this.model;
        }, 500);
      }
    },

    delayTouch() {
      if (this.v) {
        if (this.vName) {
          this.vName.$reset();
        }
        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => (this.vName ? this.vName.$touch() : ''), 1000);
      } else {
        this.valid = false;
        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => {
          this.valid = true;
        }, 1000);
      }
    },

    getAttributesData(options) {
      if (options.text) {
        return options.text;
      }

      if (this.customLabel) {
        return this.customLabel(options);
      }

      if (typeof this.labelSelect === 'object') {
        let items = options;
        this.labelSelect.forEach(item => {
          items = items[item];
        });

        return items;
      }
      return options[this.labelSelect];
    },

    format() {
      const select = window.$(`#select_${this.name}`);
      const vm = this;

      const options = {
        placeholder: this.placeholder,
        theme: 'bootstrap4',
        width: '100%',
        language: 'pt-BR',
        allowClear: true,
        minimumInputLength: 3,
        ajax: {
          delay: 500,
          transport(params, success) {
            http
              .get(vm.ajaxUrl, {
                params: { [vm.ajaxParam]: params.data.term, page: params.data.page },
              })
              .then(response => {
                vm.models = response.data.data.data;
                success(response.data);
              })
              .catch(() => {
                vm.$alert.show(
                  '',
                  'Houve um erro ao realizar a busca, tente novamente mais tarde.',
                  'error',
                );
              });
          },
          processResults(data, params) {
            // eslint-disable-next-line
            params.page = params.page || 1;

            return {
              results: data.data.data,
              pagination: {
                more: params.page * data.data.per_page < data.data.total,
              },
            };
          },
        },
        templateResult(item) {
          if (item.loading) {
            return item.text;
          }
          return vm.getAttributesData(item);
        },
        templateSelection(item) {
          if (!item.id) {
            return item.text;
          }

          return vm.getAttributesData(item);
        },
      };

      window
        .$(`#select_${this.name}`)
        .select2(options)
        .on('change', () => {
          if (this.returnObject) {
            const object = this.models?.find(item => item.id === select.val());
            this.$emit('input', object || null);
          } else {
            this.$emit('input', select.val() || this.selected || null);
          }
        });

      if (!this.multiple) {
        select.val(this.selected).trigger('change');
      }
    },
  },

  mounted() {
    this.format();

    this.$nextTick(() => {
      if (Object.keys(this.objectSelected || {})?.length) {
        const option = new Option(
          this.getAttributesData(this.objectSelected),
          this.selected,
          true,
          true,
        );
        window.$(`#select_${this.name}`).append(option).trigger('change');
      }
    });
  },

  destroyed() {
    window.$(`#select_${this.name}`).off().select2('destroy');
  },
};
</script>

<template>
  <div
    :class="{
      'has-error': isDanger,
      'form-group': !formSm,
    }"
  >
    <label :for="this.name" v-if="label">
      {{ label }}
      <span class="text-danger" v-if="required">*</span>
      <span v-if="info" v-tooltip="info">
        <InformationIcon :size="16" class="text-info" />
      </span>
    </label>
    <select
      :id="`select_${name}`"
      :class="{
        'form-control-sm': formSm,
        'is-invalid': v && vName ? vName.$dirty && vName.$invalid : false,
      }"
      class="select2-hidden-accessible"
      v-bind:name="name"
      v-bind:multiple="multiple"
    >
      <option value=""></option>
    </select>

    <div v-if="!errorInPlaceholder">
      <div :key="index" v-for="(error, index) in Object.keys(textValidations)">
        <div
          class="invalid-feedback"
          style="display: block"
          v-if="v ? vName && vName[error] === false && vName['$dirty'] : false"
        >
          &times; {{ textValidations[error] }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.has-error .selection .select2-selection {
  border-color: #dc3545 !important;
}
</style>
